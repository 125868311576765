<template>
  <div>
    <div class="banner">
      <ul class="banner_ul">
        <li>
          <div class="banner_li" style="cursor: default;" v-lazy:background-image="require('../assets/images/bannerBg.png')">
            <div class="container">
              <div class="banner_t">
                <!-- <p><span>国家区块链创新应用试点</span><span>DCI申领新应用试点</span></p> -->
                <!-- <p><img src="../assets/images/bannerImg01.png" alt=""><img src="../assets/images/bannerImg02.png" alt=""></p> -->
                <!-- <div><strong>数字作品版权存证服务平台</strong> -->
                  <!-- <p>中宣部版权管理局试点任务</p> -->
                <!-- </div> -->
                <!-- <p>权属确认│授权结算│维权保护</p> -->
                <img v-lazy="require('../assets/images/bannerImg01.png')" alt="">
                <!-- <router-link to="/ExistingEvidence" @click="goto('/ExistingEvidence')">立即存证</router-link> -->
                <button class="bannerBtn" @click="goto('/ExistingEvidence')">立即存证</button>
              </div>
              <div class="banner_b">
                <button class="banner_b_li" @click="goto('/ExistingEvidence')"><div><img src="../assets/images/bannerIcon01.png" alt=""></div><div><p>版权存证</p><span>全网AI智能检索监控存证</span></div></button>
                <button class="banner_b_li" @click="goto('/Register')"><div><img src="../assets/images/bannerIcon02.png" alt=""></div><div><p>版权登记</p><span>版权局审核通过即刻发证</span></div></button>
                <button class="banner_b_li" @click="goto('/DuplicateCheck')"><div><img src="../assets/images/bannerIcon03.png" alt=""></div><div><p>学术检测</p><span>论文查重检测学术不端行为</span></div></button>
                <!-- <router-link to="/ExistingEvidence" class="banner_b_li"><div><img src="../assets/images/bannerIcon01.png" alt=""></div><div><p>版权存证</p><span>全网AI智能检索监控存证</span></div></router-link>
                <router-link to="/Register" class="banner_b_li"><div><img src="../assets/images/bannerIcon02.png" alt=""></div><div><p>版权登记</p><span>版权局审核通过即刻发证</span></div></router-link>
                <router-link to="/DuplicateCheck" class="banner_b_li"><div><img src="../assets/images/bannerIcon03.png" alt=""></div><div><p>学术检测</p><span>论文查重检测学术不端行为</span></div></router-link> -->
                <router-link to="/Register/list" class="banner_b_li"><div><img src="../assets/images/bannerIcon04.png" alt=""></div><div><p>证书查询</p><span>官方认证凭证书编号查询</span></div></router-link>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="block block0">
      <div class="container">
        <div class="title"><p>区块链快速存证</p></div>
        <h3>利用区块链技术,实现对作品创作时间和内容的快速存证,并即时生成存证证书,为维权提供强有力的证据</h3>
        <a-row class="block0Div">
          <a-col class="block0BoxL" :md="24" :lg="5" :xl="5"><p>区块链全网AI智能检索监控存证</p><router-link to="/ExistingEvidence">立即存证</router-link></a-col>
          <a-col class="block0BoxR" :md="24" :lg="19" :xl="19">
            <a-row>
              <a-col class="block0Box" :md="24" :lg="8" :xl="8"><img src="../assets/images/block0Img01.png" alt=""><div><p>上链确权存证</p><span>采用区块链技术，提供原创作品存证保护服务</span></div></a-col>
              <a-col class="block0Box" :md="24" :lg="8" :xl="8"><img src="../assets/images/block0Img02.png" alt=""><div><p>全网侵权检测</p><span>依托大数据区块链文字监测技术，精准定位侵权线索</span></div></a-col>
              <a-col class="block0Box" :md="24" :lg="8" :xl="8"><img src="../assets/images/block0Img03.png" alt=""><div><p>作品侵权可查</p><span>原创作品可实时溯源查证</span></div></a-col>
            </a-row>
            <a-row>
              <a-col class="block0Box" :md="24" :lg="8" :xl="8"><img src="../assets/images/block0Img04.png" alt=""><div><p>在线智能签约</p><span>提供智能合约，快速签署，利益分成</span></div></a-col>
              <a-col class="block0Box" :md="24" :lg="8" :xl="8"><img src="../assets/images/block0Img05.png" alt=""><div><p>权威部门合作</p><span>最高人民法院中国行为法学会合作</span></div></a-col>
              <a-col class="block0Box" :md="24" :lg="8" :xl="8"><img src="../assets/images/block0Img06.png" alt=""><div><p>专业团队协助</p><span>专业技术团队与执行团队协作，高效存取、合理维权</span></div></a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="block block1">
      <div class="container">
        <!-- <div class="title"><img src="../assets/images/block1Img04.png" alt=""><p>即时申领 DCI</p></div>
        <h3>基于 DCI 体系3.0标准升级，帮助原创作者便捷申领 DCI，凭 DCI 可申请国家版权局作品登记证书（数字版）</h3>
        <h3>数字化版权服务新模式，更高效便捷更省钱</h3>
        <router-link class="block1Btn" to="/DCIInputForm">立即申领</router-link> -->
        <div class="block1Box">
          <ul>
            <li :class="{active:block1Index==1}" @click="block1Index = 1"><img src="../assets/images/block1Img01.png" alt=""><p>区块链存证升级</p><span>即时完成权属确认+数据上链</span></li>
            <li :class="{active:block1Index==2}" @click="block1Index = 2"><img src="../assets/images/block1Img02.png" alt=""><p>算法识别更高效</p><span>作品权属信息、独创性、查重等</span></li>
            <li :class="{active:block1Index==3}" @click="block1Index = 3"><img src="../assets/images/block1Img03.png" alt=""><p>按需办理数字作品版权登记</p><span>一键申请、体验便捷、高效权威</span></li>
          </ul>
          <div v-if="block1Index == 1">
            <img src="../assets/images/block1Img05.png" alt="">
            <p>为您的原创作品申领DCI(数字作品版权唯一标识符)</p>
          </div>
          <div v-if="block1Index == 2">
            <img src="../assets/images/block1Img06.png" alt="">
            <p>高精度内容识别算法,DCI标准审核秒级反馈</p>
          </div>
          <div v-if="block1Index == 3">
            <img src="../assets/images/block1Img07.png" alt="">
            <p>满足您在多类应用场景中对作品版权权属证明的诉求</p>
          </div>
        </div>
      </div>
    </div>
    <div class="block block1_2">
      <div class="block1_2T">
        <div class="container">
          <div class="title"><p>实时数据</p></div>
          <div class="dataBox">
            <a-row class="recordRow">
              <a-col class="recordCol" :md="24" :lg="8" :xl="8" v-for="(item,index) in reporList0" :key="index" >
                <router-link to="/Record" class="block" :ref="refName+index" :message="reporList0">
                  <div class="topWrap">
                    <div>
                      <p><ICountUp :delay="delay" :endVal="item.title" /><span>{{item.sign}}</span></p>
                      <p>{{item.desc}}</p>
                    </div>
                  </div>
                </router-link>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
      <div class="block1_2B">
        <div class="container">
          <!-- 数字报表板块 -->
          <div class="reportView">
            <div class="fluid container">
              <a-row>
                <a-col :md="24" :lg="12" :xl="6" v-for="(item,index) in reporList" :key="index">
                  <div class="block">
                    <img :src="'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/ipChain-icon'+(index+1)+'.png'">
                    <div>
                      <div class="tittle">
                        <span class="dot" :class="{'blueDot':item.color=='blue','orangeDot':item.color=='orange','greenDot':item.color=='green'}"> </span>
                        <span> {{item.tittle}} </span>
                      </div>
                      <h3>
                        <ICountUp :delay="delay" :endVal="item.num"/>
                        <span class="dan"> {{item.dan}} </span>
                      </h3>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block block2">
      <div class="container">
        <div class="title"><p>中国知链为权属确认护航</p></div>
        <a-row>
          <a-col class="block2Box" :md="24" :lg="8" :xl="8"><img src="../assets/images/block2Img01.png" alt=""><p>版权 AI 计算引擎</p><span>相似内容的提取精确到“帧”，秒级反馈比对结果。查重比对精准度高，避免作品重复登记。</span></a-col>
          <a-col class="block2Box" :md="24" :lg="8" :xl="8"><img src="../assets/images/block2Img02.png" alt=""><p>作品匹配算法能力</p><span>作品分类匹配精准到《著作权法》规定分类；智能判断，精准匹配锁定权属。</span></a-col>
          <a-col class="block2Box" :md="24" :lg="8" :xl="8"><img src="../assets/images/block2Img03.png" alt=""><p>区块链版权协作网络</p><span>依托区块链多方共识机制，构建全流程可见、全链路可信、全节点共同监管的全新版权服务协助网络。</span></a-col>
        </a-row>
      </div>
    </div>
    <div class="block block3">
      <div class="container">
        <div class="title"><p>学术论文版权登记证书</p></div>
        <h3>湖北省数字版权综合服务平台，直连湖北省版权保护中心，权威可信，打破线下冗余流程，节省时间成本，最大化提高登记效率，版权局审核通过即刻发证，权威保护，维权证明。</h3>
        <a-row>
          <a-col :md="24" :lg="8" :xl="8"><div class="block3Box"><div><p>作品登记双保险</p><span>登记完成，颁发湖北省版权局作品登记证书</span><span>国家版权局监制，全国通用</span></div></div></a-col>
          <a-col :md="24" :lg="8" :xl="8"><div class="block3Box"><div><p>支持国家版权局数据库查验</p><span>凭作者身份证号码或证书编码在国家版权局</span><span>全国作品登记数据库管理平台查询</span></div></div></a-col>
          <!-- <a-col :md="24" :lg="8" :xl="8"><div class="block3Box"><div><p>自主研发的区块链架构</p><span>中心化内容溯源和筛选实现</span><span>内容的存储、检索、版权保护和知识传播</span></div></div></a-col> -->
          <!-- <a-col :md="24" :lg="8" :xl="8"><div class="block3Box"><div><p>学术检测查重的应用</p><span>上传学术论文，自行建立文献比对库，检测更有针对性</span></div></div></a-col> -->
          <a-col :md="24" :lg="8" :xl="8"><div class="block3Box"><div><p>论文学术检测的必要性</p><span>版权登记前该作品已通过第三方权威机构学术检测，符合学术诚信要求，为申请人在学位申请、职称评审、职级晋升等领域提供参考依据</span></div></div></a-col>
        </a-row>
      </div>
    </div>
    <div class="block block4">
      <div class="container">
        <!-- <div class="title"><p>版权登记流程</p></div> -->
        <div class="title"><p>作品版权确权流程</p></div>
        <a-row>
          <a-col :md="24" :lg="4" :xl="4" class="block4Box"><img src="../assets/images/block4Img01.png" alt=""><p>实名认证</p></a-col>
          <a-col :md="0" :lg="1" :xl="1" class="block4Icon"><img src="../assets/images/block4Img06.png" alt=""></a-col>
          <a-col :md="24" :lg="4" :xl="4" class="block4Box"><img src="../assets/images/block4Img02.png" alt=""><p>上传作品</p></a-col>
          <a-col :md="0" :lg="1" :xl="1" class="block4Icon"><img src="../assets/images/block4Img06.png" alt=""></a-col>
          <a-col :md="24" :lg="4" :xl="4" class="block4Box"><img src="../assets/images/block4Img03.png" alt=""><p>填写信息</p></a-col>
          <a-col :md="0" :lg="1" :xl="1" class="block4Icon"><img src="../assets/images/block4Img06.png" alt=""></a-col>
          <a-col :md="24" :lg="4" :xl="4" class="block4Box"><img src="../assets/images/block4Img04.png" alt=""><p>提交审核</p></a-col>
          <a-col :md="0" :lg="1" :xl="1" class="block4Icon"><img src="../assets/images/block4Img06.png" alt=""></a-col>
          <a-col :md="24" :lg="4" :xl="4" class="block4Box"><img src="../assets/images/block4Img05.png" alt=""><p>获取证书</p></a-col>
        </a-row>
      </div>
    </div>
    <div class="block block5">
      <div class="container">
        <div class="title"><p>作品版权确权</p></div>
        <el-carousel :loop="false" :autoplay="false">
            <el-carousel-item class="el-car-item" v-for="(list, index) in dataList" :key="index">
              <div class="block5Li" v-for="(item,index2) in list" :key="index2">
                <!-- <router-link v-if="item.orderNo" :to="{path:'/Register/detail',query: {orderNo:item.orderNo}}"> -->
                <router-link v-if="item.orderNo" to="">
                  <img v-if="item.certificateInfo" :src="item.certificateInfo.jpgUrl" />
                  <img v-else src="../assets/images/certificateNone.png" />
                  <p>{{item.subject}}</p>
                  <!-- <span>{{item.certificateInfo.body.data.obligee}}</span> -->
                  <span v-if="item.certificateInfo">{{item.certificateInfo.body.data.author}}</span><span v-else>--</span>
                  <!-- <div v-if="item.certificateInfo">{{item.certificateInfo.body.data.registerCode}}</div><div v-else>--</div> -->
                  <div v-if="item.createTime">{{item.createTimeText}}</div><div v-else>--</div>
                </router-link >
              </div>
            </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="block block9">
      <div class="container">
        <div class="title"><p>中国知链学术论文区块链节点</p></div>
        <div class="nodeTable">
          <table>
            <tr>
              <th style="width: 28%;">节点名称</th>
              <th style="width: 16%;">节点性质</th>
              <th style="width: 11%;"><span>平均出块</span><br>时间(秒)</th>
              <th style="width: 16%;">cpu used</th>
              <th style="width: 16%;">memory used</th>
              <th style="width: 13%;">节点详情</th>
            </tr>
          </table>
          <vue-seamless-scroll  :data="nodeList"  :class-option="optionHover" class="vueScroll">
            <table>
              <tr v-for="(item,index) in nodeList" :key="index">
                  <td style="width: 28%;">{{item.name}}</td>
                  <td style="width: 16%;">
                    <span v-if="item.xingzhi == 1" class="xingzhi xingzhi1">成员节点</span>
                    <span v-if="item.xingzhi == 2" class="xingzhi xingzhi2">验证节点</span>
                    <span v-if="item.xingzhi == 3" class="xingzhi xingzhi3">RPC节点</span>
                  </td>
                  <td style="width: 11%;">{{item.pingjunshijian}}</td>
                  <td style="width: 16%;"><span class="cupUsed">{{item.cupUsed}}%</span><el-progress class="progress1" type="line" :stroke-width="8" :percentage="item.cupUsed" :show-text="false" /></td>
                  <td style="width: 16%;"><span class="memoryUsed">{{item.memoryUsed}}%</span><el-progress class="progress2" type="line" :stroke-width="8" :percentage="item.memoryUsed" :show-text="false" /></td>
                  <td style="width: 13%;">
                    <span :class="item.guanming?'guanming1':'guanming2'">{{item.guanming?'已冠名':'未冠名'}}</span>
                  </td>
              </tr>
              <!-- <tr>
                  <td></td><td></td><td></td><td></td><td></td>
                  <td class="pagination"><i class="icon iconfont">&#xe633;</i><i class="icon iconfont">&#xe673;</i></td>
              </tr> -->
          </table>
        </vue-seamless-scroll>
      </div>
      </div>
    </div>
    <div class="block block6">
      <div class="container">
        <div class="title"><p>作品版权确权公示区</p></div>
          <table>
            <tr>
              <th>证书编号</th>
              <th>作品名称</th>
              <!-- <th>著作权人</th> -->
              <th>作者</th>
              <th>存证日期</th>
            </tr>
          </table>
          <vue-seamless-scroll  :data="publicityList"  :class-option="optionHover" class="vueScroll">
            <table>
              <tr v-for="(item,index) in publicityList" :key="index">
                <!-- <td><span v-if="item.certificateInfo">{{item.certificateInfo.body.data.registerCode}}</span><span v-else>--</span></td> -->
                <td><span v-if="item.certificateInfo">{{item.certificateInfo.body.certificateCode}}</span><span v-else>--</span></td>
                <td>{{item.subject}}</td>
                <!-- <td>{{item.certificateInfo.body.data.obligee}}</td> -->
                <td><span v-if="item.certificateInfo">{{item.certificateInfo.body.data.author}}</span><span v-else>--</span></td>
                <td>{{item.createTimeText}}</td>
              </tr>
            </table>
          </vue-seamless-scroll>
      </div>
    </div>
    <div class="block block7">
      <div class="container">
        <div class="title"><p>准备了你关心的常见问题</p></div>
        <div class="faq">
          <div>Q：什么是DCI?</div>
          <p>DCI 全称“Digital Copyright Identifier”，中文名称“数字作品版权唯一标识符”，用于标识和描述数字网络环境下著作权人和与著作权有关的权利人与作品之间一一对应的版权权属关系的一组字符。</p>
          <!-- <div>Q：什么是DCI体系？</div>
          <p>DCI 体系是以我国自主创新的数字作品版权唯一标识符（DCI）标准为引领，以区块链、云计算、大数据、人工智能等先进技术集成应用为支撑的，涵盖标准体系、创新机制、技术集成、服务产品、新型基础设施和应用生态体系于一体的综合性互联网版权服务创新体系与治理机制。 该体系是中国版权保护中心针对互联网版权核心问题，在多年从事版权公共服务经验积累和对全球互联网版权保护的法律、技术和模式等研究与探索的基础上，自主创新提出具有革命性的数字作品版权公共服务创新模式。以“共生 共治 共享”为核心理念，面向权利人及相关主体提供以 DCI 标准为基础的版权权属确认、授权结算、维权保护等版权公共服务。。</p> -->
          <div>Q：作品登记证书（数字版）是什么？它有什么法律效力？</div>
          <p>中国版权保护中心基于数字作品版权链（DCI 体系 3.0）面向互联网平台开展的登记业务新模式，为获得DCI编码的用户申请获得作品登记证书（数字版）提供数字著作权登记业务支持。申请获得作品登记证书（数字版），可作为作品权利归属的初步证明材料。是互联网环境下维护自身合法权益的重要途径之一。
            <br>依照《关于规范电子版作品登记证书的通知》（国版函〔2017〕14号）规定各作品登记机关出具的数字版作品登记证书，其法律效力完全相同；作品登记机关出具的数字版作品登记证书，与同一作品的纸质版登记证书的法律效力完全相同。</p>
          <!-- <div>Q：DCI 申领与作品登记证书（数字版）的关系是什么？</div>
          <p>用户须获得 DCI 编码后，才能申请作品登记证书（数字版）。DCI 申领可以帮助用户（著作权权利人）快速完成作品权属确认，作品登记证书（数字版）继承 DCI 申领数据，获得 DCI 编码用户可在短时间内取得作品登记证书（数字版）。</p> -->
          <div>Q：登记论文前需要准备哪些资料？</div>
          <p>1.身份证正、反、手持三张照片。<br>2.创作完成时间、创作完成省市，如已发表提供发表时间及发表地点。</p>
          <div>Q：什么渠道可以公开查询到自己的作品著作权登记？</div>
          <p>全国作品登记信息数据库管理平台（<a href="http://qgzpdj.ccopyright.com.cn/" target="_blank">http://qgzpdj.ccopyright.com.cn/</a>）：通过审查的作品可以在次月10日后通过登记号、作著作权人、作品名称等查询相关信息。</p>
          <div>Q：登记论文有什么好处？已出版的论文还需要登记吗？</div>
          <p>在论文发生侵权案件后，一些论文通常会出现版权状况难以判断、版权归属难以确认等问题，不利于权利人保护和行使权利，完成论文后进行版权登记是对论文以及权属最权威的认证方式，提升论文的可信度与权威性。<br>通过作品公示公开可以防让被人抄袭或盗用，有利于保护作者的学术名誉和利益。学校、事业单位、企业等主体的个人凭借证书可以获得职称的晋级，获得奖励、政策支持等。</p>
        </div>
      </div>
    </div>
    <div class="block block8">
      <div class="container">
        <div class="title"><p>作品版权确权服务平台</p></div>
        <p class="blcok8text">权属确认│授权结算│维权保护</p>
        <div class="block8Btn">
          <button @click="goto('/Register')">版权登记</button>
          <button @click="goto('/DCIInputForm')">DCI申领</button>
          <!-- <router-link to="/Register">版权登记</router-link>
          <router-link to="/DCIInputForm">DCI申领</router-link> -->
        </div>
      </div>
    </div>

    <!-- 侧边悬浮按钮 -->
    <div class="floatBtn">
      <!-- <router-link to="/Register" class="floatBtn1"><span class="icon iconfont">&#xe64e;</span><p>版权登记</p></router-link>
      <div class="floatBtn1"><span class="icon iconfont">&#xe61c;</span><p>扫码查询</p><div><img src="../assets/images/kefuCode.png" alt=""><p>微信扫码查询</p></div></div> -->
      <div class="floatBtn2" v-if="btnFlag" @click="backTop"><span class="icon iconfont">&#xe67a;</span></div>
    </div>


    <login-register-forget-dialog v-if="showLogin" @close="showLogin = false" @login="handleLogin" :dialogType="dialogType"></login-register-forget-dialog>

  </div>
</template>

<script>
import { queryRegisterList ,queryExistEvidenceSummary2,queryAllExistEvidence} from '@/api/EvidenceService'
import { queryChainSummary } from '@/api/ChainService'
import VueSeamlessScroll from "vue-seamless-scroll";
import ICountUp from 'vue-countup-v2'
import { mapGetters } from 'vuex'
import loginRegisterForgetDialog from "../components/loginRegisterForgetDialog.vue";
export default {
  name: 'Home',
  components: {
    VueSeamlessScroll,
    ICountUp,
    loginRegisterForgetDialog,
  },
  data () {
    return {
      //Slider configuration [obj]
      options: {
        currentPage: 0
      },
      dataList: [],
      publicityList: [],
      nodeList: [
        {name: '广东万方数据信息科技有限公司',xingzhi: 3,pingjunshijian: '6s',cupUsed: 55,memoryUsed: 40,guanming: true,},
        {name: '中国数字图书馆有限责任公司',xingzhi: 2,pingjunshijian: '5s',cupUsed: 60,memoryUsed: 68,guanming: true,},
        {name: '中国出版集团东方出版中心',xingzhi: 2,pingjunshijian: '5s',cupUsed: 68,memoryUsed: 91,guanming: true,},
        {name: '长江日报',xingzhi: 1,pingjunshijian: '5s',cupUsed: 50,memoryUsed: 64,guanming: true,},
        {name: '《长江文库》',xingzhi: 2,pingjunshijian: '5s',cupUsed: 74,memoryUsed: 84,guanming: true,},
        {name: '《电脑校园》',xingzhi: 2,pingjunshijian: '5s',cupUsed: 70,memoryUsed: 81,guanming: true,},
        {name: '《商情》',xingzhi: 2,pingjunshijian: '5s',cupUsed: 62,memoryUsed: 52,guanming: true,},
        {name: '《中国教育信息化》杂志社',xingzhi: 1,pingjunshijian: '8s',cupUsed: 65,memoryUsed: 48,guanming: true,},
        {name: '武汉天卷传媒有限公司',xingzhi: 2,pingjunshijian: '9s',cupUsed: 70,memoryUsed: 75,guanming: true,},
        {name: '北京中品信联管理咨询有限公司',xingzhi: 2,pingjunshijian: '5s',cupUsed: 75,memoryUsed: 75,guanming: true,},
        // {name: '节点10',xingzhi: 2,pingjunshijian: '4s',cupUsed: 66,memoryUsed: 51,guanming: false,},
        {name: '国商信公共服务平台技术（北京）有限公司',xingzhi: 2,pingjunshijian: '1s',cupUsed: 89,memoryUsed: 69,guanming: true,},
        {name: '深圳市中品信用技术有限公司',xingzhi: 1,pingjunshijian: '6s',cupUsed: 55,memoryUsed: 40,guanming: true,},
        // {name: '节点13',xingzhi: 3,pingjunshijian: '3s',cupUsed: 60,memoryUsed: 69,guanming: false,},
        {name: '大百科开源出版（武汉）有限公司',xingzhi: 2,pingjunshijian: '5s',cupUsed: 60,memoryUsed: 90,guanming: true,},
        // {name: '节点15',xingzhi: 2,pingjunshijian: '8s',cupUsed: 65,memoryUsed: 48,guanming: false,},
        {name: '武汉蝌蚪信息技术有限公司',xingzhi: 2,pingjunshijian: '9s',cupUsed: 70,memoryUsed: 75,guanming: true,},
        {name: '广州时波网络技术有限公司',xingzhi: 2,pingjunshijian: '5s',cupUsed: 75,memoryUsed: 75,guanming: true,},
        {name: '广东宏大认证咨询有限公司',xingzhi: 2,pingjunshijian: '4s',cupUsed: 66,memoryUsed: 51,guanming: true,},
        // {name: '节点20',xingzhi: 1,pingjunshijian: '1s',cupUsed: 89,memoryUsed: 69,guanming: false,},
        // {name: '节点21',xingzhi: 3,pingjunshijian: '3s',cupUsed: 60,memoryUsed: 69,guanming: false,},
        // {name: '节点22',xingzhi: 2,pingjunshijian: '1s',cupUsed: 89,memoryUsed: 69,guanming: false,},
        // {name: '节点23',xingzhi: 1,pingjunshijian: '6s',cupUsed: 55,memoryUsed: 40,guanming: false,},
        // {name: '节点24',xingzhi: 1,pingjunshijian: '3s',cupUsed: 60,memoryUsed: 69,guanming: false,},
        // {name: '节点25',xingzhi: 1,pingjunshijian: '5s',cupUsed: 60,memoryUsed: 90,guanming: false,},
        // {name: '节点26',xingzhi: 2,pingjunshijian: '8s',cupUsed: 65,memoryUsed: 48,guanming: false,},
        // {name: '节点27',xingzhi: 2,pingjunshijian: '9s',cupUsed: 70,memoryUsed: 75,guanming: false,},
        // {name: '节点28',xingzhi: 1,pingjunshijian: '5s',cupUsed: 75,memoryUsed: 75,guanming: false,},
        // {name: '节点29',xingzhi: 1,pingjunshijian: '4s',cupUsed: 66,memoryUsed: 51,guanming: false,},
        // {name: '节点30',xingzhi: 2,pingjunshijian: '1s',cupUsed: 89,memoryUsed: 69,guanming: false,},
        // {name: '节点31',xingzhi: 1,pingjunshijian: '6s',cupUsed: 55,memoryUsed: 40,guanming: false,},
        // {name: '节点32',xingzhi: 1,pingjunshijian: '3s',cupUsed: 60,memoryUsed: 69,guanming: false,},
        // {name: '节点33',xingzhi: 1,pingjunshijian: '5s',cupUsed: 60,memoryUsed: 90,guanming: false,},
        // {name: '节点34',xingzhi: 1,pingjunshijian: '8s',cupUsed: 65,memoryUsed: 48,guanming: false,},
        // {name: '节点35',xingzhi: 1,pingjunshijian: '9s',cupUsed: 70,memoryUsed: 75,guanming: false,},
        // {name: '节点36',xingzhi: 1,pingjunshijian: '5s',cupUsed: 75,memoryUsed: 75,guanming: false,},
        // {name: '节点37',xingzhi: 1,pingjunshijian: '4s',cupUsed: 66,memoryUsed: 51,guanming: false,},
        // {name: '节点38',xingzhi: 2,pingjunshijian: '1s',cupUsed: 89,memoryUsed: 69,guanming: false,},
        // {name: '节点39',xingzhi: 1,pingjunshijian: '6s',cupUsed: 55,memoryUsed: 40,guanming: false,},
        // {name: '节点40',xingzhi: 1,pingjunshijian: '3s',cupUsed: 60,memoryUsed: 69,guanming: false,},
        // {name: '节点41',xingzhi: 1,pingjunshijian: '3s',cupUsed: 60,memoryUsed: 69,guanming: false,},
        // {name: '节点42',xingzhi: 2,pingjunshijian: '3s',cupUsed: 60,memoryUsed: 69,guanming: false,},
        // {name: '节点43',xingzhi: 1,pingjunshijian: '3s',cupUsed: 60,memoryUsed: 69,guanming: false,},
        // {name: '节点44',xingzhi: 1,pingjunshijian: '3s',cupUsed: 60,memoryUsed: 69,guanming: false,},
        // {name: '节点45',xingzhi: 2,pingjunshijian: '3s',cupUsed: 60,memoryUsed: 69,guanming: false,},
        // {name: '节点46',xingzhi: 1,pingjunshijian: '3s',cupUsed: 60,memoryUsed: 69,guanming: false,},
        // {name: '节点47',xingzhi: 1,pingjunshijian: '3s',cupUsed: 60,memoryUsed: 69,guanming: false,},
        // {name: '节点48',xingzhi: 1,pingjunshijian: '3s',cupUsed: 60,memoryUsed: 69,guanming: false,},
        // {name: '节点49',xingzhi: 1,pingjunshijian: '3s',cupUsed: 60,memoryUsed: 69,guanming: false,},
        // {name: '节点50',xingzhi: 2,pingjunshijian: '3s',cupUsed: 60,memoryUsed: 69,guanming: false,},
      ],

      btnFlag: false,

      block1Index: 1,

      reporList0:[
        {
          title: 0,
          desc:'中国知链总存证书',
          sign: '份'
        },
        {
          title: 0,
          desc:'今日新增存证数',
          sign: '份'
        },
        {
          title: 0,
          desc:'交易金额总数',
          sign: '元'
        },
      ],
      refName:'RecordBlock',
      reporList: [
        {
          tittle: '最新高度',
          num: 1277390,
          color: 'green'
        },
        {
          tittle: '最新区块时间',
          num: 12,
          dan: '秒前',
          color: 'blue'
        },
        {
          tittle: '区块交易数',
          num: 1211123,
          color: 'orange'
        },
        {
          tittle: '活跃节点数',
          num: 912,
          color: 'orange'
        },
        {
          tittle: '实时TPS',
          num: 0,
          color: 'orange'
        },
        {
          tittle: '未确认交易',
          num: 0,
          color: 'orange'
        },
        {
          tittle: '平均区块时间',
          num: 9,
          dan: '秒',
          color: 'blue'
        },
        {
          tittle: '网络节点',
          num: 14,
          color: 'green'
        },
      ],
      delay: 1000,

      targetPath: '', // 存储点击的导航路径
      showLogin: false,
      dialogType: '1',
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapGetters(['userInfo']),

    optionHover() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  // window对象，所有浏览器都支持window对象。它表示浏览器窗口，监听滚动事件
  created () {
    window.addEventListener('scroll', this.scrollToTop)

    // 生成 40%左右，+-5%浮动的 随机数
    for(let i in this.nodeList){
      let pingjunshijianNum = this.getRandomNum(1,9);
      let cupUsedNum = this.getRandomNum(35,45);
      let memoryUsedNum = this.getRandomNum(35,45);
      this.nodeList[i].pingjunshijian = pingjunshijianNum + 's';
      this.nodeList[i].cupUsed = cupUsedNum;
      this.nodeList[i].memoryUsed = memoryUsedNum;
    }
  },
  destroyed () {
      window.removeEventListener('scroll',()=>{
          this.scrollToTop();
      });
  },
  methods: {
    init () {
        // 获取学术论文版权登记 板块列表
        let params = {
          subject:null,
          date:null,
          status:'21'
        }
        queryAllExistEvidence(1,10,params).then(({data}) => {
          // console.log("搜索登记列表",data.list);
          for (let i = 0; i < data.list.length; i++) {
            data.list[i].createTimeText= this.dayjs(data.list[i].createTime*1000).format('YYYY-MM-DD');
            if(i <= 10){  //学术论文数字作品版权登记公示区 只取最新10条
              this.publicityList = data.list;
            }
          }
          this.dataList = data.list;

          // 学术论文版权登记 板块 4个切换一版
          let newDataList = []
          let current = 0
          if(this.dataList && this.dataList.length>0){
            for(let i=0;i<=this.dataList.length-1;i++){
              if(i%4 !== 0 || i === 0 ){
                if(!newDataList[current]){
                  newDataList.push([this.dataList[i]])
                }else{
                  newDataList[current].push(this.dataList[i])
                }
              }else{
                current++
                newDataList.push([this.dataList[i]])
              }
            }
          }
          this.dataList = [...newDataList]

        })

        // 数字报表板块
        let that = this;
        queryExistEvidenceSummary2().then(({data})=>{
          // console.log(444,data);
          if (that.reporList0 && that.reporList0.length > 0) {
            that.reporList0[0].title=parseInt(data.totalCount);
            that.reporList0[1].title=parseInt(data.todayCount);
            that.reporList0[2].title=parseInt(data.totalAmount);
          }
        })
        queryChainSummary().then(({ data }) => {
          // console.log(data);
          that.reporList[0].num = data.latestBlockHeight * 1;
          that.reporList[1].num = Math.round(Date.now() / 1000) - Math.round(data.latestBlockTime);
          // that.reporList[1].num = Math.round(data.latestBlockTime);
          that.reporList[2].num = data.totalTransactionCount * 1;
          that.reporList[3].num = data.activePeerCount * 1;
          that.reporList[4].num = data.tps * 1;
          that.reporList[5].num = data.unconfirmedTransactionCount * 1;
          that.reporList[6].num = 1;
          that.reporList[7].num = data.totalPeerCount * 1;
        })

    },

    // 生成随机数函数
    getRandomNum(min, max) {
      var randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
      return randomNumber;
    },

    // 点击返回顶部方法
    backTop () {
          window.scroll({
              top: 0, 
              left: 0, 
              behavior: 'smooth'
          });
    },
  
    scrollToTop () {
      // 计算浏览器视口高度，当高度大于浏览器视口显示回顶部按钮，小于则隐藏
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      if (scrollTop > h) {
          this.btnFlag = true;
      } else {
          this.btnFlag = false;
      }
    },

    goto(url){
      // console.log(url);
      if(this.userInfo==null){
        this.showLogin = true; // 打开登录弹窗
        this.targetPath = url; // 存储目标路径
      }
      this.$router.push({path: url,query: {}});
    },
    handleLogin() {
      // console.log(111+this.targetPath);
      // this.$router.push(this.targetPath); // 登录后跳转到目标页面
      setTimeout(()=>{
        const userInfo = localStorage.getItem('userInfo');
        if (userInfo) {
          const userType = JSON.parse(userInfo).type;
          // console.log('userType---1111----:', userType);
          if(userType == 'M' && this.targetPath=='/Register'){
            this.$router.push('/Register2'); // 登录后跳转到目标页面
          }else{
            this.$router.push(this.targetPath); // 登录后跳转到目标页面
          }
        }
      },300)
    },

  }
}
</script>
<style lang="scss" scoped>
.banner {
    height: 500px;
    position: relative;
    background-color: #999;
    .banner_ul li {
      width: 100%;
      height: 500px;
      position: absolute;
      left: 0px;
      top: 0px;
      transition: all 1s;
      .banner_li {
        height: 500px;
        background-position: center center;
        // background: url('../assets/images/bannerBg.png') no-repeat 100% 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 60px 0;
        .banner_t{
          p:nth-child(1){
            span{
              display: inline-block;
              padding: 0 12px;
              margin-right: 20px;
              font-size: 20px;
              font-family: 'MicrosoftYaHei';
              font-weight: bold;
              background: linear-gradient(45deg, #FEC345 0.3662109375%, #FF8E38 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              line-height: 46px;
              border: 2px solid;
              border-image: linear-gradient(0deg, #F6B144, #F48D33) 10 10;
              border-radius: 0px 11px 0px 11px;
            }
            span:nth-child(2){
              background: linear-gradient(45deg, #FFE984 0.87890625%, #FCCF5A 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              border-image: linear-gradient(0deg, #FFE984, #FFD109) 10 10;
            }
            img{
              height: 50px;
              margin-right: 20px;
            }
          }
          div{
            display: flex;
            align-items: center;
            strong{
              font-size: 46px;
              font-family: 'MicrosoftYaHei';
              font-weight: bold;
              color: #FFFFFF;
              line-height: 100px;
              text-shadow: 0px 0px 49px rgba(0,53,136,0.53);
              background: linear-gradient(0deg, #CFD8FF 0.1220703125%, #FFFFFF 70%, #FFFFFF 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              margin-right: 20px;
            }
            p{
              font-size: 16px;
              font-family: 'MicrosoftYaHei';
              font-weight: bold;
              color: #144AC9;
              line-height: 40px;
              background: linear-gradient(90deg, #CFD8FF, #FFFFFF);
              box-shadow: 0px 0px 49px 0px rgba(0,53,136,0.53);
              border-radius: 0px 11px 0px 11px;
              padding: 0 12px;
              margin-top: 6px;
            }
          }
          p:nth-child(3){
            font-size: 14px;
            font-family: 'Microsoft YaHei';
            color: #CFE4FF;
            letter-spacing: 12px;
          }
          img{
            display: block;
            height: 200px;
            margin: 0 auto;
          }
          .bannerBtn{
            display: block;
            margin: 20px auto 25px auto;
            width: 120px;
            height: 40px;
            text-align: center;
            background: linear-gradient(45deg, #104189, #0092FA);
            border-radius: 20px;
            font-size: 16px;
            font-family: 'MicrosoftYaHei';
            font-weight: bold;
            color: #F6F7F8;
            line-height: 40px;
            border: none;
            cursor: pointer;
          }
          .bannerBtn:hover{
            background: linear-gradient(45deg, #0092FA, #104189);
          }
        }
        .banner_b{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .banner_b_li{
            width: 24%;
            height: 120px;
            background: rgba(251, 252, 255, 0.6);
            border: 1px solid rgba(255,255,255,0.22);
            box-shadow: 3px 4px 19px 0px rgba(0,23,113,0.06);
            border-radius: 4px;
            padding: 30px 12px;
            display: flex;
            align-items: center;
            transition: transform ease-in-out .2s;
            cursor: pointer;
            img{
              width: 56px;
              height: 56px;
            }
            div:nth-child(1){
              width: 56px;
              height: 56px;
              // background: rgba(255,255,255,0.25);
              // box-shadow: 0px 1px 0px 0px rgba(255,255,255,0.35);
              border-radius: 50%;
              text-align: center;
              margin-right: 12px;
              position: relative;
              transform-style: preserve-3d;
              .icon{
                color: #FFFFFF;
                font-size: 30px;
                line-height: 56px;
              }
            }
            div:nth-child(1)::before{
              display: none;
              content: '';
              width: 32px;
              height: 32px;
              background: linear-gradient(141deg, #00256B, #006DF0);
              border-radius: 50%;
              position: absolute;
              left: -8px;
              top: -6px;
              transform: translateZ(-1px) 
            }
            div:nth-child(2){
              p{
                margin-bottom: 4px;
                font-family: 'PingFangSC, PingFang SC';
                font-weight: 500;
                font-size: 24px;
                color: #002A73;
                line-height: 33px;
                text-align: left;
              }
              span{
                display: block;
                line-height: 20px;
                font-family: 'PingFangSC, PingFang SC';
                font-weight: 400;
                font-size: 14px;
                color: #464B54;
                line-height: 22px;
                text-align: left;
              }
            }
          }
          .banner_b_li:hover{
            transform: translateY(-10px);
          }
          .banner_b_li:last-child{
            div:nth-child(1){
              .icon{
                font-size: 26px;
              }
            }
          }
        }
      }
    }
}

.block{
  padding: 60px 0;
  .title{
    text-align: center;
    margin-bottom: 30px;
    p{
      font-size: 30px;
      font-family: 'Microsoft YaHei';
      color: #333333;
      line-height: 36px;
    }
  }
  h3{
    text-align: center;
    font-size: 14px;
    font-family: 'Microsoft YaHei';
    color: #999999;
    line-height: 20px;
  }
}
.block0{
  h3{margin-bottom: 50px;}
  .block0Div{box-shadow: 0px 0px 27px 0px rgba(10,93,177,0.1);}
  .block0BoxL{
    height: 316px;
    background: url(../assets/images/block0Bg.png)no-repeat;
    background-size: 100% 100%;
    padding: 0 26px;
    p{
      font-family: 'PingFangSC, PingFang SC';
      font-weight: 500;
      font-size: 24px;
      color: #EDF5FF;
      line-height: 33px;
      padding: 70px 0;
    }
    a{
      margin: 40px 0;
      display: block;
      width: 90px;
      height: 30px;
      background: rgba(128, 195, 255, 0.5);
      border-radius: 15px;
      font-family: 'SourceHanSansCN, SourceHanSansCN';
      font-size: 16px;
      color: #FFFFFF;
      line-height: 30px;
      text-align: center;
    }
  }
  .block0BoxR{
    height: 316px;
    .ant-row:nth-child(1){border-bottom: 1px solid #E8EAEC;}
    .block0Box{border-right: 1px solid #E8EAEC;height: 157.5px;background: #FFFFFF;padding: 30px;display: flex;
      img{width: 56px;height: 56px;margin-right: 20px;}
      div{
        p{font-family: 'PingFangSC, PingFang SC';
          font-weight: 500;
          font-size: 18px;
          color: #091930;
          line-height: 56px;}
        span{font-family: 'PingFangSC, PingFang SC';
            font-weight: 400;
            font-size: 12px;
            color: #3A485B;
            line-height: 20px;}
      }
    }
    .block0Box:nth-child(3n){border-right: none;}
  }
}
.block1_2{
  padding: 0;
  .block1_2T{
    height: 230px;
    width: 100%;
    background: url(../assets/images/block1_2Bg.png)no-repeat;
    background-size: 100% 100%;
    padding: 30px;
    .title p{
      font-family: 'PingFangSC, PingFang SC';
      font-weight: 400;
      font-size: 32px;
      color: #FFFFFF;
      line-height: 60px;
    }
    .recordCol a{
      display: block;
      width: 200px;
      margin: 0 auto;
      padding: 0;
      .topWrap{
        p:nth-child(1){
          white-space: nowrap;
          span{
            font-family: 'PingFangSC, PingFang SC';
            font-size: 18px;
            color: #FFFFFF;
            line-height: 26px;
          }
          span:nth-child(1){
            font-weight: bold;
            font-size: 42px;
            margin-right: 6px;
          }
        }
        p:nth-child(2){
          font-family: 'PingFangSC, PingFang SC';
          font-size: 12px;
          color: #FFFFFF;
          line-height: 20px;
          margin: 6px 0;
        }
      }
    }
  }
  .block1_2B{
    .reportView {
      padding: 15px 0;
      .block {
        background: #fff;
        box-shadow: 0 0 8px rgba(0, 109, 117, 0.06);
        padding: 32px 88px 16px 16px;
        height: 141px;
        position: relative;
        color: #001216;
        img {
          position: absolute;
          right: 0px;
          top: 4px;
          width: 66px;
        }
        h3 {
          margin: 8px 0 0 24px;
          font-size: 24px;
          display: flex;
          white-space: nowrap;
          span:nth-child(1){
            font-family: 'DIN, DIN';
            font-weight: bold;
            font-size: 28px;
            color: rgba(0,18,22,0.85);
            line-height: 48px;
          }
          span:nth-child(2){
            font-family: 'PingFangSC, PingFang SC';
            font-weight: 500;
            font-size: 16px;
            color: rgba(0,18,22,0.85);
            line-height: 28px;
            padding-top: 12px;
          }
        }
        .dan {
          margin-left: 4px;
        }
        .tittle {
          display: flex;
          align-items: center;
          span:nth-child(2){color: rgba(0,18,22,0.65);}
        }
        .dot {
          background: radial-gradient(
            circle,
            rgba(144, 178, 255, 1),
            rgba(238, 243, 255, 0.15),
            rgba(255, 255, 255, 0)
          );
          width: 18px;
          height: 18px;
          border-radius: 100%;
          margin-right: 8px;
          display: block;
        }
        .blueDot {
          background: radial-gradient(
            circle,
            rgba(144, 178, 255, 1),
            rgba(238, 243, 255, 0.15),
            rgba(255, 255, 255, 0)
          );
        }
        .greenDot {
          background: radial-gradient(
            circle,
            rgba(42, 239, 239, 1),
            rgba(204, 251, 247, 0.15),
            rgba(255, 255, 255, 0)
          );
        }
        .orangeDot {
          background: radial-gradient(
            circle,
            rgba(255, 165, 103, 1),
            rgba(255, 243, 234, 0.15),
            rgba(255, 255, 255, 0)
          );
        }
      }
    }
  }
}
.block1{
  background: #FFFFFF;
  .title{
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      height: 46px;
      margin-right: 55px;
    }
    p{
      position: relative;
    }
    p::before{
      content: '';
      width: 1px;
      height: 35px;
      background: #AAAAAA;
      position: absolute;
      top: 0;
      left: -28px;
    }
  }
  .block1Btn{
    display: block;
    margin: 30px auto;
    width: 120px;
    height: 40px;
    text-align: center;
    background: linear-gradient(45deg, #104189, #0092FA);
    border-radius: 20px;
    font-size: 16px;
    font-family: 'MicrosoftYaHei';
    font-weight: bold;
    color: #F6F7F8;
    line-height: 40px;
    border: none;
    cursor: pointer;
  }
  .block1Btn:hover{
    background: linear-gradient(45deg, #0092FA, #104189);
  }
  .block1Box{
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 27px 0px rgba(10,93,177,0.1);
    display: flex;
    align-items: center;
    ul{
      width: 360px;
      li{
        display: block;
        height: 160px;
        background: #F7FCFF;
        position: relative;
        padding: 46px 50px;
        cursor: pointer;
        img{
          position: absolute;
          width: 70px;
          left: 15px;
          top: 32px;
        }
        p{
          font-size: 20px;
          font-family: 'MicrosoftYaHei';
          font-weight: bold;
          color: #222222;
          line-height: 38px;
        }
        span{
          font-size: 16px;
          font-family: 'Microsoft YaHei';
          color: #999999;
          line-height: 28px;
        }
      }
      li.active{
        background: linear-gradient(45deg, #104189 0%, #0092FA 80%, #0092FA 100%);
        position: relative;
        p{
          color: #FEFEFF;
        }
        span{
          color: #86CDFF;
        }
      }
      li.active::after{
        content: '';
        width: 0;
        height: 0;
        border-left: 17px solid #0092FA;
        border-top: 17px solid transparent;
        border-bottom: 17px solid transparent;
        position: absolute;
        right: -17px;
        top: 62px;
      }
    }
    div{
      width: calc(100% - 360px);
      text-align: center;
      img{
        height: 340px;
      }
      p{
        font-size: 18px;
        font-family: 'Microsoft YaHei';
        font-weight: 400;
        color: #333333;
        line-height: 26px;
      }
    }
  }
}
.block2{
  background: #F6F6F6;
  .block2Box{
    text-align: center;
    margin-top: 30px;
    img{
      height: 160px;
      transition: transform ease-in-out .2s;
    }
    img:hover{
      transform: translateY(-10px);
    }
    p{
      font-size: 24px;
      font-family: 'Microsoft YaHei';
      color: #333333;
      line-height: 30px;
      margin-bottom: 30px;
    }
    span{
      display: block;
      font-size: 14px;
      font-family: 'Microsoft YaHei';
      color: #999999;
      line-height: 24px;
      padding: 0 80px;
    }
  }
}
.block3{
  display: none;
  background: #FFFFFF;
  h3{
    padding: 0 180px;
  }
  .block3Box{
    position: relative;
    padding-top: 290px;
    margin: 50px 20px 0 20px;
    background: url(../assets/images/block3Img01.png)no-repeat;
    background-size: 100% 100%;
    height: 450px;
    img{
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }
    div{
      padding: 30px 40px;
      z-index: 1;
      p{
        font-size: 18px;
        font-family: 'MicrosoftYaHei';
        font-weight: bold;
        color: #4E5969;
        line-height: 30px;
      }
      span{
        font-size: 14px;
        font-family: 'Microsoft YaHei';
        color: #666666;
        line-height: 19px;
      }
    }
  }
  .ant-col:nth-child(2){
    .block3Box{
      background: url(../assets/images/block3Img02.png)no-repeat;
      background-size: 100% 100%;
    }
  }
  .ant-col:nth-child(3){
    .block3Box{
      background: url(../assets/images/block3Img03.png)no-repeat;
      background-size: 100% 100%;
    }
  }
}
.block4{
  background: url(../assets/images/block4Bg.png)no-repeat;
  background-size: cover;
  .title{
    p{
      color: #FFFFFF;
    }
  }
  .block4Box{
    height: 245px;
    text-align: center;
    img{
      height: 64px;
      margin: 50px 0 40px 0;
    }
    p{
      font-size: 18px;
      font-family: 'Microsoft YaHei';
      font-weight: 400;
      color: #252525;
      line-height: 24px;
    }
  }
  .block4Box:nth-child(1){
    background: url(../assets/images/block4Bg02.png)no-repeat;
    background-size: cover;
  }
  .block4Box:nth-child(3){
    background: url(../assets/images/block4Bg03.png)no-repeat;
    background-size: cover;
  }
  .block4Box:nth-child(5){
    background: url(../assets/images/block4Bg04.png)no-repeat;
    background-size: cover;
  }
  .block4Box:nth-child(7){
    background: url(../assets/images/block4Bg05.png)no-repeat;
    background-size: cover;
  }
  .block4Box:nth-child(9){
    background: url(../assets/images/block4Bg06.png)no-repeat;
    background-size: cover;
  }
  .block4Icon{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 245px;
    img{

    }
  }
}
.block5{
  background: #FFFFFF;
  ::v-deep .el-carousel__arrow--left{
      background: url(../assets/images/homeIcon2.png)no-repeat;
      background-size: cover;
    }
  ::v-deep .el-icon-arrow-right{
    display: none;
    }
    ::v-deep .el-icon-arrow-left{
    display: none;
    }
  ::v-deep .el-carousel__arrow--right{
      background: url(../assets/images/homeIcon3.png)no-repeat;
      background-size: cover;
    }
    ::v-deep .el-carousel__container{
      height: 405px;
    }
  .el-car-item {
    width: 100%;
    display: flex;
    padding: 0 80px;
    .block5Li{
      width: 23%;
      height: 390px;
      margin-right: calc(8% / 3);
      padding: 20px;
      background: linear-gradient(0deg, #FEFEFE 0%, #F4F6F8 100%);
      border: 3px solid #FFFFFF;
      box-shadow: 0px 4px 18px 0px rgba(186,205,226,0.16);
      border-radius: 4px;
      a{
        text-decoration: none;
        img{
          width: 100%;
          height: 255px;
        }
        p{
          font-size: 14px;
          color: #494949;
          line-height: 22px;
          font-weight: 500;
          margin: 5px 0;
          overflow:hidden;
          text-overflow:ellipsis;
          // display:-webkit-box;
          // -webkit-box-orient:vertical;
          // -webkit-line-clamp:2;
          white-space: nowrap;
        }
        span{
          font-size: 14px;
          color: #485260;
          line-height: 20px;
          opacity: 0.8;
        }
        div{
          width: 100%;
          height: 22px;
          line-height: 22px;
          background: #EAF3FF;
          padding: 0 10px;
          font-size: 12px;
          color: #104189;
          margin-top: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .block5Li:nth-child(4n){
      margin-right: 0;
    }
  }
  .readMore{
    display: block;
    width: 240px;
    height: 40px;
    line-height: 40px;
    background: #F1F5FC;
    border-radius: 4px;
    font-size: 14px;
    color: #1353B4;
    border: none;
    cursor: pointer;
    margin: 20px auto;
    text-align: center;
    text-decoration: none;
  }
  .readMore:hover{
    background: #1353B4;
    color: #FFFFFF;
  }
}
.block6{
  background: #F6F7F8;
  .vueScroll{
    height: 290px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  table{
    width: 100%;
    th{
      background: #F2F3F5;
      height: 42px;
      line-height: 42px;
      font-size: 16px;
      font-weight: normal;
      color: #1D2129;
      text-align: center;
      border: 1px solid #E8EFFD;
    }
    th:nth-child(1),td:nth-child(1){
      width: 25%;
    }
    th:nth-child(2),td:nth-child(2){
      width: 35%;
    }
    th:nth-child(3),td:nth-child(3){
      width: 17%;
    }
    th:nth-child(4),td:nth-child(4){
      width: 23%;
    }
    td{
      display: inline-block;
      height: 58px;
      line-height: 58px;
      padding: 0 20px;
      border: .5px solid #E8EFFD;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #666666;
    }
  }
  tr{
    background: #F9FBFF;
  }
  tr:nth-child(2n){
      background: #F3F5F8;
    }
  table:nth-child(2){
    border: 1px solid #0873C0;
  }
}
.block7{
  background: #FFFFFF;
  .faq{
    div{
      font-size: 22px;
      font-family: 'Microsoft YaHei';
      font-weight: 400;
      color: #1D2129;
      line-height: 42px;
    }
    p{
      font-size: 16px;
      font-family: 'Microsoft YaHei';
      font-weight: 400;
      color: #999999;
      line-height: 24px;
      margin-bottom: 30px;
      padding-left: 2em;
    }
    p:last-child{
      margin-bottom: 0;
    }
  }
}
.block8{
  background: url(../assets/images/block8Bg.png)no-repeat;
  background-size: cover;
  .title{
    p{
      font-size: 40px;
      font-family: 'MicrosoftYaHei';
      font-weight: bold;
      color: #FFFFFF;
      line-height: 48px;
    }
  }
  .blcok8text{
    font-size: 14px;
    font-family: 'Microsoft YaHei';
    font-weight: 400;
    color: #97A7ED;
    line-height: 20px;
    letter-spacing: 10px;
    text-align: center;
    margin-bottom: 30px;
  }
  .block8Btn{
    display: flex;
    align-items: center;
    justify-content: center;
    button{
      background: transparent;
      display: block;
      width: 90px;
      height: 32px;
      line-height: 30px;
      border: 1px solid #FFFFFF;
      font-size: 14px;
      font-family: 'Microsoft YaHei';
      color: #FFFFFF;
      text-align: center;
      border-radius: 4px;
      margin: 0 8px;
      cursor: pointer;
    }
  }
}
.block9{
    background: url(../assets/images/block9Bg.png)no-repeat;
    background-size: 100% 100%;
    .title{
      margin-bottom: 60px;
      p{
        color: #FFFFFF;
      }
    }
    .vueScroll{
      height: 480px;
      overflow: hidden;
      margin-bottom: 20px;
    }
    .nodeTable{
      width: calc(100% - 40px);
      background: linear-gradient(225deg, rgba(3, 49, 228, .47) 0%, rgba(0, 31, 118, .47) 0%, rgba(4, 56, 190, .47) 100%, rgba(0, 13, 81, .47) 100%);
      border-image: linear-gradient(226deg, rgba(81, 241, 255, 1), rgba(98, 171, 255, 1)) 2 2;
      border: 2px solid #5CC2FF;
      border-radius: 8px;
      position: relative;
      margin: 20px;
      transform-style: preserve-3d;	
      table{
        width: 100%;
        overflow: hidden;
        tr{
            th{
                background: rgba(68, 162, 255, .47);
                height: 58px;
                line-height: 58px;
                font-size: 16px;
                font-family: 'SourceHanSansCN, SourceHanSansCN';
                font-weight: 500;
                color: #FFFFFF;
                padding: 0 20px;
                text-align: center;
                span{
                  color: #FFFFFF;
                  white-space: nowrap;
                }
            }
            th:nth-child(3){
                line-height: 25px;
                padding: 5px 0;
            }
            td{
              height: 58px;
              line-height: 58px;
              font-size: 14px;
              font-family: 'SourceHanSansCN, SourceHanSansCN';
              color: #FFFFFF;
              padding: 0 20px;
              text-align: center;
              // display: inline-block;
              white-space: nowrap;
              span.xingzhi{
                  display: inline-block;
                  border-radius: 4px;
                  font-weight: 500;
                  line-height: 28px;
                  height: 30px;
                  padding: 0 28px;
              }
              span.xingzhi1{
                  border: 1px solid #01A38A;
                  color: #01A38A;
              }
              span.xingzhi2{
                  border: 1px solid #FF9B01;
                  color: #FF9B01;
              }
              span.xingzhi3{
                  border: 1px solid #B031F5;
                  color: #B031F5;
              }
              span.cupUsed,span.memoryUsed{
                display: block;
                width: 100%;
                line-height: 16px;
                text-align: right;
                font-size: 12px;
              }
              span.guanming1{
                display: block;
                text-align: center;
                height: 24px;
                line-height: 24px;
                background: rgba(1, 163, 138, .4);
                border-radius: 12px;
                color: #00B186;
                font-size: 14px;
              }
              span.guanming2{
                display: block;
                text-align: center;
                height: 24px;
                line-height: 24px;
                background: rgba(255, 187, 83, .4);
                border-radius: 12px;
                color: #FFBB53;
                font-size: 14px;
              }
              .progress1{
                /deep/.el-progress-bar__outer{
                  background: rgba(255, 255, 255, .4);
                }
                /deep/.el-progress-bar__inner{
                  background: linear-gradient(270deg, #0057CC 0%, #00A4FF 100%);
                  animation: progress 1s ease;
                }
              }
              .progress2{
                /deep/.el-progress-bar__outer{
                  background: rgba(255, 255, 255, .4);
                }
                /deep/.el-progress-bar__inner{
                  background: linear-gradient(270deg, #00876F 0%, #39FFDB 100%);
                  animation: progress 2s ease;
                }
              }
              @keyframes progress {
                from {
                  width: 0;
                }
                to {
                }
              }
            }
            td.pagination{
              i{
                display: inline-block;
                width: 50%;
                height: 100%;
                font-size: 28px;
                color: #ffffff;
                opacity: 0.5;
                cursor: pointer;
              }
              i:hover{
                opacity: .9;
              }
            }
        }
        tr:nth-child(2n+1){
            background: rgba(255, 255, 255, .12);
        }
      }
    }
    .nodeTable::before{
        content: '';
        width: calc(100% + 40px);
        height: calc(100% + 40px);
        background: url(../assets/images/block9Bg2.png)no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: -20px;
        top: -20px;
        transform: translateZ(-1px);  
    }
}

.floatBtn{
  height: 400px;
  position: fixed;
  right: 0;
  top: 50%;
  margin-top: -200px;
  .floatBtn1{
    display: block;
    width: 50px;
    height: 148px;
    background: linear-gradient(0deg, #E1E8EF 0%, #F4F6F8 100%);
    border: 2px solid #FFFFFF;
    box-shadow: 0px 4px 18px 0px rgba(186,205,226,0.5);
    border-radius: 25px;
    margin-bottom: 22px;
    padding: 20px 12px;
    text-align: center;
    cursor: pointer;
    span{
      font-size: 20px;
      color: #4E5969;
    }
    p{
      font-size: 16px;
      font-family: 'Microsoft YaHei';
      color: #4E5969;
      line-height: 18px;
    }
  }
  .floatBtn1:nth-child(2){
    position: relative;
    div{
      display: none;
      width: 166px;
      height: 194px;
      background: #FFFFFF;
      box-shadow: 0px 0px 4px 0px rgba(186,205,226,0.61);
      border-radius: 10px;
      padding: 10px 0;
      text-align: center;
      position: absolute;
      top: -23px;
      left: - 180px;
      img{
        width: 150px;
      }
      p{
        font-size: 14px;
        font-family: 'Microsoft YaHei';
        font-weight: 400;
        color: #333333;
        line-height: 24px;
      }
    }
  }
  .floatBtn1:nth-child(2):hover{
    div{
      display: block;
    }
  }
  .floatBtn2{
    width: 50px;
    height: 50px;
    background: linear-gradient(0deg, #E1E8EF 0%, #F4F6F8 100%);
    border: 2px solid #FFFFFF;
    box-shadow: 0px 4px 18px 0px rgba(186,205,226,0.5);
    border-radius: 25px;
    text-align: center;
    cursor: pointer;
    span{
      line-height: 50px;
      font-size: 22px;
      color: #4E5969;
    }
    position: fixed;
    right: 10px;
    bottom: 100px;
  }
}


// 适配移动端
@media screen and (max-width: 991px){
  .banner{height: 440px;padding: 20px 0;
    li{
      height: 440px!important;
      .banner_li{
        height: 450px!important;
        padding-top: 40px!important;
        .banner_t{
          img{width: 100%;height: auto!important;}
          p{white-space: nowrap;}
          p:nth-child(1) img{
            height: 24px!important;
          }
          p:nth-child(1) img:nth-child(2){
            margin-right: 0;
          }
          div{
            margin-bottom: 10px;
            strong{
              font-size: 30px!important;
              line-height: 40px!important;
              margin-top: 15px;
            }
            p{
              display: none!important;
            }
          }
        }
        .banner_b_li{
          img{width: 40px!important;height: 40px!important;}
        }
      }
    }
  }
  .floatBtn,.banner_b{
    display: block!important;
    margin-top: 15px!important;
  }
  .floatBtn,.banner_b .banner_b_li{
    width: 48%!important;
    float: left;
    margin-right: 4%;
    height: 100px!important;
    margin-bottom: 10px;
    padding: 20px 10px 20px 10px!important;
    div:nth-child(1){
      margin-right: 10px!important;
      width: 40px!important;
      height: 40px!important;
      .icon{
        line-height: 40px!important;
        font-size: 22px!important;
      }
    }
    div:nth-child(2){
      p{
        font-size: 14px!important;
        margin-bottom: 5px!important;
      }
      span{
        font-size: 12px!important;
      }
    }
  }
  .floatBtn,.banner_b .banner_b_li:nth-child(2n){
    margin-right: 0;
  }
  .block .title p{
    font-size: 28px!important;
  }
  .block0 .block0BoxR{
    height: auto;
  }
  .block0 .block0BoxR .ant-row:nth-child(1){
    border-bottom: none;
  }
  .block0 .block0BoxR .block0Box{
    border-bottom: 1px solid #E8EAEC;
    border-right: none;
  }
  .block1_2 .block1_2T{
    height: auto;
    .recordCol{margin-bottom: 20px;}
  }
  .block1{
    .title{
      display: block;
      img{
        margin-right: 0!important;
        margin-bottom: 10px;
      }
    }
    .block1Box{
      display: block;
      ul{
        width: 100%!important;
        display: flex;
        align-items: center;
        li{
          display: inline-block;
          width: calc(100% / 3);
          padding: 20px 10px;
          p{
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          span{
            font-size: 12px;
          }
        }
        li.active::after{
          display: none!important;
        }
      }
      div{
        width: 100%;
        padding: 20px;
        img{
          width: 80%;
          height: auto;
        }
        p{
          font-size: 14px;
        }
      }
    }
  }
  .block3{
    h3{padding: 0 20px!important;}
    .block3Box{margin: 20px 0 0 0;}
  }
  .block4{
    .ant-row{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .block4Icon{
      height: 50px;
      width: 20px;
    }
    .block4Box{
      width: 18%!important;
      height: 85px!important;
      border-radius: 4px;
      img{
        height: 30px!important;
        margin: 15px 0 10px 0!important;
      }
      p{
        font-size: 12px!important;
      }
    }
  }
  .block5{
    ::v-deep .el-carousel__container{
      height: 430px!important;
    }
    .el-car-item {
      display: block;
      padding: 0;
      .block5Li{
        width: 48%!important;
        margin-right: 4%;
        float: left;
        margin-bottom: 10px;
        padding: 15px!important;
        height: 205px!important;
        a{
          img{
            height: 100px!important;
          }
          p{
            font-size: 12px!important;
            margin: 5px 0 0 0!important;
          }
          span{
            font-size: 12px!important;
          }
          div{
            margin-top: 5px!important;
          }
        }
      }
      .block5Li:nth-child(2n){
        margin-right: 0;
      }
    }
  }
  .block6{
    .vueScroll{
      height: 190px!important ;
      margin-bottom: 0!important;
    }
    table{
      th{
        font-size: 14px!important;
      }
      td{
        height: 38px!important;
        line-height: 38px!important;
        padding: 0 10px!important;
        font-size: 12px;
      }
    }
  } 
  .block7 .faq{
    div{
      font-size: 14px!important;
    }
    p{
      font-size: 12px!important;
      margin-bottom: 10px!important;
    }
  }
  .block9{
    .title{
      margin-bottom: 30px;
    }
    .nodeTable{
      width: calc(100% - 10px);
      margin: 5px;
      table tr{
        th{
          padding: 0;
          font-size: 10px;
          height: 40px;
          line-height: 40px;
        }
        th:nth-child(3){
          padding: 5px 0;
          line-height: 15px;
        }
        td{
          padding: 0 2px;
          font-size: 10px;
          height: 32px;
          line-height: 32px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span.xingzhi,span.guanming1,span.guanming2{
            font-size: 10px;
            padding: 0 2px;
            height: 22px;
            line-height: 22px;
          }
        }
        td:nth-child(4),td:nth-child(5){
          padding: 0 10px;
        }
      } 
    } 
    .nodeTable::before{
      width: calc(100% + 20px)!important;
      height: calc(100% + 20px)!important;
      left: -10px!important;
      top: -10px!important;
    }
  } 
  
}
</style>